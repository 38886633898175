import { X_PADDING } from "@/features/remotion/text-styles/text"
import { fillTextBox, fitText, measureText } from "@remotion/layout-utils"
import { z } from "zod"

import {
  subtitleElementSchema,
  textElementSchema,
} from "@/lib/validations/element"

export const fitTextToBox = (el: z.infer<typeof textElementSchema>) => {
  const { fontSize } = el.style
  let numericFontSize = fontSize
  let overflows = true
  while (el.text && overflows && numericFontSize > 1) {
    const measurableStyles = {
      additionalStyles: {
        overflows: "no-wrap",
        lineHeight: `${el.style.lineHeightPercent}%`,
      },
      fontWeight: el.style.fontWeight,
      textTransform: el.style.casing,
      letterSpacing: el.style.letterSpacing
        ? `${el.style.letterSpacing}px`
        : undefined,
      fontFamily: el.style.fontFamily,
      fontSize: numericFontSize,
      validateFontIsLoaded: true,
    }
    const { height } = measureText({
      ...measurableStyles,
      text: "placeholder",
    })

    const lines = Math.floor(
      ((el.transform.height === "auto" ? 720 : el.transform.height) -
        X_PADDING) /
        height
    )

    const maxBoxWidth =
      (el.transform.width === "auto" ? 1080 : el.transform.width) -
      // Text has padding of 0.5rem per side
      X_PADDING

    const box = fillTextBox({
      maxBoxWidth,
      // do not allow words to break
      maxLines: Math.min(Math.max(1, lines), el.text.split(" ").length),
    })
    overflows = el.text
      .split(" ")
      .map((text) => {
        const { exceedsBox } = box.add({
          ...measurableStyles,
          text,
        })
        // if the single-line text exceeds the box, we can't fit it
        const { fontSize } = fitText({
          withinWidth: maxBoxWidth,
          ...measurableStyles,
          text,
        })
        return exceedsBox || fontSize < numericFontSize
      })
      .some(Boolean)
    if (overflows) numericFontSize = Math.max(numericFontSize - 2, 1)
  }
  return numericFontSize
}

export const fitTextElement = (
  text: string,
  el: z.infer<typeof subtitleElementSchema>
): number => {
  const dims = fitText({
    text,
    withinWidth: el.transform.width === "auto" ? 1000 : el.transform.width,
    letterSpacing: el.style.letterSpacing
      ? `${Math.floor(el.style.letterSpacing)}px`
      : undefined,
    fontFamily: el.style.fontFamily,
    fontWeight: el.style.highlightFontStrokeWidth ?? el.style.fontWeight,
    // validating the font loading in editor can cause crashes:
    // https://github.com/Post-Sunday/editor/issues/1280
    validateFontIsLoaded: true,
  })

  return Math.floor(Math.min(dims.fontSize, el.style.fontSize))
}
