import { z } from "zod"

import { getStringifiedShadow } from "@postsunday/remotion-shared/lib/colors"
import { nestedFrameElementSchema } from "@postsunday/remotion-shared/lib/validations/element"

export const Frame = ({
  children,
  ...el
}: Omit<z.infer<typeof nestedFrameElementSchema>, "children"> & {
  children?: JSX.Element | JSX.Element[]
}) => {
  return (
    <div
      className="size-full"
      style={{
        borderRadius: el.style.borderRadius,
        ...(el.style.background
          ? { background: el.style.background }
          : {
              backgroundColor: el.style.backgroundColor,
            }),
        ...(el.style.backgroundBlur
          ? {
              backdropFilter: `blur(${el.style.backgroundBlur}px)`,
            }
          : {}),
        filter: `${
          "shadow" in el.style && el.style.shadow
            ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
            : ""
        } ${el.style.layerBlur ? `blur(${el.style.layerBlur}px)` : ""}`.trim(),
      }}
    >
      {children}
    </div>
  )
}
