import { AbsoluteFill } from "remotion"
import { z } from "zod"

import { El } from "@postsunday/remotion-shared/components/element"
import { getPageFromElementTree } from "@postsunday/remotion-shared/lib/utils/element-tree"
import { usedInputPropsSchema } from "@postsunday/remotion-shared/lib/validations/input-props"
import { cn } from "@postsunday/shared/lib/utils"

export const BaseComp = (
  props: z.infer<typeof usedInputPropsSchema>
): JSX.Element => {
  const pageEl = getPageFromElementTree(props.commonProperties.elements)
  return (
    <AbsoluteFill className={cn("bg-black")}>
      <El el={pageEl} key={pageEl.id} props={props} />
    </AbsoluteFill>
  )
}
