import { z } from "zod"

import { subtitleElementSchema } from "@postsunday/remotion-shared/lib/validations/element"
import { savedInputPropsSchema } from "@postsunday/remotion-shared/lib/validations/input-props"
import { WaitForFonts } from "@postsunday/remotion-shared/providers/waitForFonts"
import { Text } from "@postsunday/remotion-shared/text-styles/text"
import { fitTextElement } from "@postsunday/remotion-shared/text-styles/utils"

import { ClippingComp } from "./clipping"
import { SubtitlesAtChunk } from "./subtitles-at-chunk"

export const Subtitles = ({
  el,
  props,
}: {
  el: z.infer<typeof subtitleElementSchema>
  props: z.infer<typeof savedInputPropsSchema>
}) => {
  return (
    <WaitForFonts fontFamily={el.style.fontFamily}>
      <ClippingComp {...props} disablePremounting sequenceProps={{}}>
        {({ startFromFrame, endFrame, key }) => (
          <p
            className="size-full"
            style={{
              textAlign: el.style.textAlign,
              fontFamily: el.style.fontFamily,
              color: el.style.color,
              textTransform: el.style.casing,
              backgroundColor: el.style.backgroundColor,
              paddingTop: el.style.paddingVertical,
              paddingBottom: el.style.paddingVertical,
              paddingLeft: el.style.paddingHorizontal,
              paddingRight: el.style.paddingHorizontal,
              borderRadius: el.style.borderRadius,
              lineHeight: `${el.style.lineHeightPercent}%`,
            }}
            key={key}
          >
            <SubtitlesAtChunk
              clippingBasedProperties={props.clippingBasedProperties}
              wordsAtATime={el.wordsAtATime}
              animationStyle={el.animationStyle}
              startFromFrame={startFromFrame}
              endFrame={endFrame}
              highlightBackgroundColor={
                el.style.disableHighlight
                  ? "transparent"
                  : el.style.highlightBackgroundColor
              }
              highlightTextColor={
                (el.style.disableHighlight
                  ? el.style.color
                  : el.style.highlightTextColor) ?? "#ffffff"
              }
              highlightFontStrokeWidth={el.style.highlightFontStrokeWidth}
              style={{
                color: el.style.color,
              }}
              inactiveColor={el.inactiveColor}
              borderRadius={el.style.borderRadius}
            >
              {(word, allWordsInCurrentChunk) => {
                const {
                  color: _1,
                  fontSize,
                  paddingHorizontal: _2,
                  paddingVertical: _3,
                  borderRadius: _4,
                  backgroundColor: _5,
                  fontWeight: inactiveFontWeight,
                  ...restStyles
                } = el.style
                return (
                  <Text
                    content={word}
                    style={{
                      fontSize: !el?.disableFitText
                        ? fitTextElement(allWordsInCurrentChunk, el)
                        : fontSize,
                      fontWeight: el.style.highlightFontStrokeWidth
                        ? undefined
                        : inactiveFontWeight,
                      ...restStyles,
                    }}
                  />
                )
              }}
            </SubtitlesAtChunk>
          </p>
        )}
      </ClippingComp>
    </WaitForFonts>
  )
}
