import { z } from "zod"

import { getStringifiedShadow } from "@/lib/colors"
import { rectangleElementSchema } from "@/lib/validations/element"

export const Rectangle = (el: z.infer<typeof rectangleElementSchema>) => {
  return (
    <div
      className="size-full"
      style={{
        borderRadius: el.style.borderRadius,
        ...(el.style.background
          ? { background: el.style.background }
          : {
              backgroundColor: el.style.backgroundColor,
            }),
        ...(el.style.backgroundBlur
          ? {
              backdropFilter: `blur(${el.style.backgroundBlur}px)`,
            }
          : {}),
        filter: `${
          "shadow" in el.style && el.style.shadow
            ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
            : ""
        } ${el.style.layerBlur ? `blur(${el.style.layerBlur}px)` : ""}`.trim(),
      }}
    />
  )
}
