import { SENTENCE_TERMINATORS } from "./constants"
import { Words } from "./types"

export const grabWordsAtATime = (
  words: Words,
  wordsAtATime: number
): Words[] => {
  // grab up to wordsAtATime words unless we hit a sentence terminator
  // if we hit a sentence terminator, return the words we have so far
  // and start a new array
  const scenes: Words[] = []
  const queue = [...words] // copy
  while (queue.length > 0) {
    const words: Words = []
    let word = queue.shift()
    while (word) {
      words.push(word)
      if (
        words.length === wordsAtATime ||
        SENTENCE_TERMINATORS.some((terminator) =>
          word?.word.endsWith(terminator)
        )
      ) {
        break
      }
      word = queue.shift()
    }
    scenes.push(words)
  }
  return scenes
}
