import { z } from "zod"

import { FlatElementSchema } from "@postsunday/remotion-shared/lib/validations/element"
import {
  ElementIdsTree,
  elementInfoSchema,
} from "@postsunday/remotion-shared/lib/validations/element-tree"

export const DOCUMENT_NODE_ID = "DOCUMENT"
export const PAGE_NODE_ID = "PAGE"

export const getDefaultElementIdsTree = (
  childrenOfPage?: ElementIdsTree[] | null
): ElementIdsTree => [
  DOCUMENT_NODE_ID,
  [[PAGE_NODE_ID, childrenOfPage ?? null]],
]

export const defaultElementIdsTree = [
  DOCUMENT_NODE_ID,
  [[PAGE_NODE_ID, null]],
] as const satisfies ElementIdsTree

export const defaultDocumentElement: FlatElementSchema = {
  id: DOCUMENT_NODE_ID,
  type: "document",
  name: "Document",
  hidden: false,
}
export const defaultPageElement = {
  type: "frame",
  id: PAGE_NODE_ID,
  name: "Page",
  hidden: false,
  style: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  transform: {
    height: 712,
    width: 400,
    horizontalConstraint: "left",
    verticalConstraint: "top",
    x: 0,
    y: 0,
  },
} as const satisfies FlatElementSchema
export const defaultRequiredElements = [
  defaultDocumentElement,
  defaultPageElement,
] as const satisfies FlatElementSchema[]

export const defaultElementInfo = {
  flatElements: defaultRequiredElements,
  elementIdsTree: defaultElementIdsTree,
} as const satisfies z.infer<typeof elementInfoSchema>
