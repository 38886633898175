import { createEnumObject } from "@postsunday/shared/utils"

export const aspectRatios = ["16:9", "1:1", "9:16"] as const

export const contentInteractions = ["downloaded", "copied", "posted"] as const
export type ContentInteractionType = (typeof contentInteractions)[number]

export const contentTypes = ["image", "video", "thumbnail"] as const
export type ContentType = (typeof contentTypes)[number]

export const visibilityTypes = ["draft", "public", "church-specific"] as const
export const TemplateVisibilityType = createEnumObject(visibilityTypes)

export const approvalStatuses = ["pending", "approved", "rejected"] as const

export const notificationTypes = [
  "activity",
  "marketing",
  "post-approval",
] as const
export const NotificationType = createEnumObject(notificationTypes)
export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType]

export const supportedLanguageCodes = [
  "en",
  "en_au",
  "en_uk",
  "en_us",
  "es",
  "fr",
  "de",
  "it",
  "pt",
  "nl",
  "hi",
  "ja",
  "zh",
  "fi",
  "ko",
  "pl",
  "ru",
  "tr",
  "uk",
  "vi",
] as const

export const SupportedLanguageCode = createEnumObject(supportedLanguageCodes)
export type SupportedLanguageCode =
  (typeof SupportedLanguageCode)[keyof typeof SupportedLanguageCode]

export const assetTypes = [
  "brand-asset",
  "church-logo",
  "white-logo",
  "dark-logo",
] as const
export const AssetType = createEnumObject(assetTypes)
export type AssetType = (typeof AssetType)[keyof typeof AssetType]

export const sermonStatuses = [
  "uploaded",
  "processing", // pipeline processsing
  "draft", // ready for qa by moderator/admin
  "review", // ready for review by customer
] as const
export const SermonStatus = createEnumObject(sermonStatuses)
export type SermonStatus = (typeof SermonStatus)[keyof typeof SermonStatus]

export const postTypes = [
  "reel",
  "story",
  "image",
  "video",
  "email_recap",
  "discussion_questions",
  "bible_study_guide",
  "blog",
  "thumbnail",
] as const
export const PostType = createEnumObject(postTypes)
export type PostType = (typeof PostType)[keyof typeof PostType]

export const ApprovalStatus = createEnumObject(approvalStatuses)

export const socialPostStatuses = [
  "pending",
  "processing",
  "locked",
  "scheduled",
  "published",
  "failed",
] as const
export const SocialPostStatus = createEnumObject(socialPostStatuses)
export type SocialPostStatus =
  (typeof SocialPostStatus)[keyof typeof SocialPostStatus]
