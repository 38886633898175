import { z } from "zod"

import { aspectRatioSchema } from "@/lib/validations/store"

export const initialShadow = {
  x: 0,
  y: 8,
  blur: 12,
  color: "#00000040",
  spread: 9,
} as const

export const SENTENCE_TERMINATORS = [
  ".",
  "?",
  "!",
  "।",
  "॥",
  "…",
  "‥",
  "。",
  "．",
  "？",
  "！",
] as const satisfies string[]

export const DIMENSIONS = {
  "16:9": {
    w: 712,
    h: 400,
  },
  "1:1": {
    w: 712,
    h: 712,
  },
  "9:16": {
    w: 400,
    h: 712,
  },
} as const satisfies Record<
  z.infer<typeof aspectRatioSchema>,
  { w: number; h: number }
>

export const SERMON_SHOTS_TO_RENDER = 6
export const SERMON_SHOTS_MINIMUM_SIZE = 35 * 1024 // 35kb
