import { z } from "zod"

// type TextOnlyStyles = {
//   fontSize: number;
//   fontFamily: string;
//   textAlign: "left" | "center" | "right";
//   casing: "none" | "uppercase" | "lowercase";
// } & (
//   | { boxSizingType: "fluid-width-height"; height: null; width: null }
//   | { boxSizingType: "fluid-height-fixed-width"; height: null; width: number }
//   | { boxSizingType: "fixed-width-fixed-height"; height: number; width: number }
// );

export const layerBasedStylesSchema = z.object({
  layerBlur: z.number().optional(),
  opacity: z.number().optional(),
})

export const containerBasedStylesSchema = z.object({
  paddingHorizontal: z.number().optional(),
  paddingVertical: z.number().optional(),
})

export const textBasedStylesSchema = z.object({
  fontSize: z.number(),
  fontFamily: z.string(),
  textAlign: z.enum(["left", "center", "right"]).optional(),
  casing: z.enum(["uppercase", "lowercase", "none"]).optional(),
  fontStyle: z.string().optional(),
  fontWeight: z.string().optional(),
  letterSpacing: z.number().optional(),
  lineHeightPercent: z.number().optional(),
})
export const subtitleBasedStyleSchema = z.object({
  disableHighlight: z.boolean().optional(),
  textShadow: z.string().optional(),
  textShadowColor: z.string().optional(),
  highlightBackgroundColor: z.string().optional().default("#ef4445"),
  highlightTextColor: z.string().optional().default("white"),
  highlightFontStrokeWidth: z.string().optional(),
})

export const shapeBasedStylesSchema = z.object({
  borderRadius: z.number().default(0),
})
export const fillBasedStylesSchema = z.object({
  backgroundColor: z.string(),
  color: z.string().optional(),
})
export const effectBasedStylesSchema = z.object({
  shadow: z
    .object({
      x: z.number(),
      y: z.number(),
      blur: z.number(),
      spread: z.number(),
      color: z.string(),
    })
    .nullish(),
  backgroundBlur: z.number().default(0).nullish(),
  layerBlur: z.number().default(0).nullish(),
})
export type Shadow = z.infer<typeof effectBasedStylesSchema>["shadow"]

export const gradientBgSupportedStylesSchema = z.object({
  background: z.string().optional(),
})

export const mediaBasedStylesSchema = z.object({
  objectFit: z.enum(["cover", "fill", "contain"]),
})
