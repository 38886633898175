export const SENTENCE_TERMINATORS = [
  ".",
  "?",
  "!",
  "।",
  "॥",
  "…",
  "‥",
  "。",
  "．",
  "？",
  "！",
] as const satisfies string[]
