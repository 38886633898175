import { useMemo } from "react"
import { Audiogram } from "@/features/remotion/components/audiogram"
import { ClippingComp } from "@/features/remotion/components/clipping"
import { Frame } from "@/features/remotion/components/frame"
import { Rectangle } from "@/features/remotion/components/rectangle"
import { Subtitles } from "@/features/remotion/components/subtitles"
import { VideoOverlay } from "@/features/remotion/components/video-overlay"
import { DELAY_RENDER_RETRIES } from "@/features/remotion/constants"
import { TextFitted } from "@/features/remotion/text-styles/text"
import { Audio, Img, OffthreadVideo } from "remotion"
import { z } from "zod"

import { getStringifiedShadow } from "@/lib/colors"
import { cn, getElementDomId } from "@/lib/utils"
import { nestedElementSchema } from "@/lib/validations/element"
import { savedInputPropsSchema } from "@/lib/validations/input-props"

export const El = ({
  el,
  props,
}: {
  el: z.infer<typeof nestedElementSchema>
  props: z.infer<typeof savedInputPropsSchema>
}) => {
  const content = useMemo(() => {
    switch (el.type) {
      case "audio":
        return (
          <Audio
            pauseWhenBuffering={!props.isBufferingDisabled}
            volume={el.volume}
            src={el.src}
            delayRenderRetries={DELAY_RENDER_RETRIES}
            delayRenderTimeoutInMilliseconds={60 * 1000 * 2}
          />
        )
      case "image":
        return (
          <Img
            src={el.src}
            className="size-full overflow-hidden"
            style={{
              objectFit: el.style.objectFit,
              borderRadius: el.style.borderRadius,
            }}
          />
        )
      case "text":
        return <TextFitted el={el} />
      case "video":
        return (
          <OffthreadVideo
            src={el.src}
            className="size-full overflow-hidden"
            muted={el.isMuted}
            pauseWhenBuffering={!props.isBufferingDisabled}
            delayRenderTimeoutInMilliseconds={3 * 60 * 1000}
            delayRenderRetries={DELAY_RENDER_RETRIES}
            style={{
              objectFit: el.style.objectFit,
              borderRadius: el.style.borderRadius,
            }}
          />
        )
      case "frame":
        const { children, ...rest } = el
        return (
          <Frame {...rest}>
            {children?.map((child) => (
              <El key={child.id} el={child} props={props} />
            ))}
          </Frame>
        )
      case "rectangle":
        return <Rectangle {...el} />
      case "waveform":
        return (
          <ClippingComp {...props} disablePremounting sequenceProps={{}}>
            {({ startFromFrame, endFrame }) => (
              <Audiogram
                numberOfSamples={256}
                el={el}
                mirroring={el.mirroringEnabled ?? false}
                audioSegments={props.commonProperties.audioSegments}
                startFromFrame={startFromFrame}
                endFrame={endFrame}
              />
            )}
          </ClippingComp>
        )
      case "clipping":
        return (
          <ClippingComp
            {...props}
            disablePremounting={false}
            sequenceProps={{
              className: "relative size-full overflow-hidden bg-black",
              // @ts-expect-error style
              style: {
                ...el.style,
              },
            }}
          />
        )
      case "video-overlay":
        return (
          <ClippingComp
            {...props}
            disablePremounting
            sequenceProps={{
              className: "relative size-full overflow-hidden bg-black",
              style: el.style,
            }}
          >
            {({ startFromFrame, endFrame, key, isBufferingDisabled }) =>
              startFromFrame <= el.startFromFrame ||
              el.endAtFrame <= endFrame ? (
                <VideoOverlay
                  key={key}
                  {...props}
                  isBufferingDisabled={isBufferingDisabled}
                  fromFrame={el.startFromFrame - startFromFrame}
                  el={el}
                />
              ) : (
                // if this is falsy, we'll get a second main video
                <div />
              )
            }
          </ClippingComp>
        )
      case "subtitles":
        return <Subtitles el={el} props={props} />
      // document
      default:
        return null
    }
  }, [el, props])

  return (
    <div
      key={el.id}
      className={cn(
        "absolute z-[100]",
        el.hidden ? "hidden" : "flex",
        el.type !== "text" &&
          el.type !== "subtitles" &&
          el.type !== "rectangle" &&
          "overflow-hidden",
        el.type
      )}
      id={getElementDomId(el.id)}
      style={{
        ...("transform" in el
          ? {
              left: el.transform.x,
              top: el.transform.y,
              width: el.transform.width,
              height:
                (el.type === "text" || el.type === "subtitles") &&
                !el.style?.verticalAlignment
                  ? "fit-content"
                  : el.transform.height,
              opacity: el.style.opacity,
              ...(el.style.layerBlur
                ? {
                    filter: `blur(${el.style.layerBlur}px)`,
                  }
                : {}),
              filter: `${
                "shadow" in el.style &&
                el.style.shadow &&
                el.type !== "rectangle"
                  ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
                  : ""
              }`.trim(),
              backdropFilter: `${
                "backgroundBlur" in el.style &&
                el.style.backgroundBlur &&
                el.type !== "rectangle"
                  ? `blur(${el.style.backgroundBlur}px)`
                  : ""
              }`.trim(),
              rotate: el.transform.rotate,
            }
          : {}),
      }}
    >
      {content}
    </div>
  )
}
