import { ComponentProps, ReactNode, useMemo } from "react"
import { interpolate, spring, useCurrentFrame, useVideoConfig } from "remotion"
import { z } from "zod"

import { subtitleElementSchema } from "@postsunday/remotion-shared/lib/validations/element"
import { savedInputPropsSchema } from "@postsunday/remotion-shared/lib/validations/input-props"
import { DEFAULT_FPS } from "@postsunday/shared/lib/constants/server"
import { cn } from "@postsunday/shared/lib/utils"
import { grabWordsAtATime } from "@postsunday/shared/lib/utils/words/grabWordsAtATime"

export const SubtitlesAtChunk = ({
  clippingBasedProperties: { chunks: scenes },
  startFromFrame,
  endFrame,
  wordsAtATime,
  children,
  highlightBackgroundColor,
  highlightTextColor,
  highlightFontStrokeWidth,
  color,
  animationStyle,
  inactiveColor,
  ...props
}: {
  startFromFrame: number
  endFrame: number
  highlightBackgroundColor: string
  highlightTextColor: string
  highlightFontStrokeWidth?: string
  children?: (text: string, fullText: string) => ReactNode
  wordsAtATime: number
  animationStyle: z.infer<typeof subtitleElementSchema>["animationStyle"]
  inactiveColor: string | null | undefined
  borderRadius?: string | number
} & Omit<ComponentProps<"p">, "children"> &
  Pick<z.infer<typeof savedInputPropsSchema>, "clippingBasedProperties">) => {
  const frame = useCurrentFrame()
  const { fps } = useVideoConfig()
  if (fps !== DEFAULT_FPS) {
    console.error(
      "SubtitlesAtChunk component is designed to work with 30fps videos. Please change the video settings to 30fps. This clip fps:",
      fps
    )
  }

  // this should be true
  const firstWordStartFrame = Math.ceil(
    (scenes?.at(0)?.words?.at(0)?.end ?? 0) * DEFAULT_FPS
  )
  const shouldDisplayFirstSub = frame + startFromFrame <= firstWordStartFrame

  // Get all words that will be spoken
  const words = useMemo(
    () =>
      scenes
        .flatMap(({ words }) => words.map((word) => word))
        .filter(
          // if the word.endFrame is after the startFromFrame OR word.startFrame is before the endFrame
          (word) =>
            startFromFrame <= Math.ceil(word.end * DEFAULT_FPS) ||
            Math.floor(word.start * DEFAULT_FPS) <= endFrame
        ),
    [scenes, startFromFrame, endFrame]
  )

  // grab words at a time, but stop grabbing if it's a new sentence
  // this avoids "reading ahead" at pauses
  const subtitles = grabWordsAtATime(words, wordsAtATime ?? 4)

  const currentScene =
    subtitles.find((sub) => {
      const firstWord = sub.at(0)
      const lastWord = sub.at(-1)
      if (!firstWord || !lastWord) return false
      const elapsed = startFromFrame + frame
      const firstWordFrame = firstWord.start * DEFAULT_FPS
      const lastWordFrame = lastWord.end * DEFAULT_FPS

      const isCurrentSubtitle =
        elapsed >= firstWordFrame && elapsed <= lastWordFrame
      return isCurrentSubtitle
    }) ?? (shouldDisplayFirstSub ? subtitles[0] : [])

  // can be -1 is before first word OR after last
  const currentWordIndexInCurrentScene = currentScene?.findIndex(
    (word) =>
      word.start * DEFAULT_FPS <= frame + startFromFrame &&
      word.end * DEFAULT_FPS >= frame + startFromFrame
  )
  // can be -1 is before first word OR after last
  const lastWordIndexInCurrentScene = currentScene?.findLastIndex(
    (word) => word.start * DEFAULT_FPS <= frame + startFromFrame
  )
  const { borderRadius, ...spanProps } = props
  return currentScene?.map(({ word, id, start }, i, subtitle) => (
    <span
      {...spanProps}
      key={id}
      className={cn(
        "relative inline-block whitespace-pre-wrap transition-all duration-100",
        props.className
      )}
    >
      {
        <span
          className={i === currentWordIndexInCurrentScene ? "block" : "hidden"}
          key={1}
        >
          <BackgroundHighlight
            highlightBackgroundColor={highlightBackgroundColor}
            animationStyle={animationStyle}
            borderRadius={borderRadius}
            fromFrame={Math.floor(start * DEFAULT_FPS) - startFromFrame}
          />
        </span>
      }
      <span
        className="relative z-10"
        key={2}
        style={{
          ...(i === currentWordIndexInCurrentScene
            ? { color: highlightTextColor }
            : i <= lastWordIndexInCurrentScene && inactiveColor
              ? { color: inactiveColor }
              : { color }),
          ...(i === currentWordIndexInCurrentScene &&
            !!highlightFontStrokeWidth && {
              // https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size
              // maintains container width!
              "-webkit-text-stroke-width": highlightFontStrokeWidth,
            }),
        }}
      >
        {children?.(word, subtitle.map((sub) => sub.word).join(""))}
      </span>
    </span>
  ))
}

function BackgroundHighlight({
  highlightBackgroundColor,
  animationStyle,
  borderRadius,
  fromFrame,
}: {
  highlightBackgroundColor: string
  animationStyle: z.infer<typeof subtitleElementSchema>["animationStyle"]
  borderRadius?: number | string
  fromFrame: number
}) {
  const fps = DEFAULT_FPS
  const frame = useCurrentFrame()
  const scaleSpring = spring({
    frame,
    fps,
    durationInFrames: 10,
    delay: fromFrame,
    // https://springs.remotion.dev/
    config: { damping: 9 },
  })

  const scale = interpolate(scaleSpring, [0, 1.25], [0.7, 1], {
    extrapolateLeft: "clamp",
    extrapolateRight: "clamp",
  })

  return (
    <span
      className={cn(
        "absolute inset-x-[-3%] inset-y-0 z-0 overflow-hidden",
        typeof borderRadius === "string" && borderRadius !== "full"
          ? "mx-2"
          : typeof borderRadius === "number" && borderRadius < 15
            ? "mx-2"
            : typeof borderRadius === "number" &&
                borderRadius > 10 &&
                borderRadius < 15
              ? "mx-1"
              : animationStyle === "scale"
                ? "mx-0"
                : undefined
      )}
      style={{
        transform: animationStyle === "scale" ? `scaleX(${scale})` : undefined,
        backgroundColor: highlightBackgroundColor,
        borderRadius: borderRadius ? borderRadius : "8px",
      }}
    />
  )
}
