import React, { useEffect, useState } from "react"
import { cancelRender, continueRender, delayRender } from "remotion"

import { top250GoogleFonts } from "@/lib/fonts"

// Missing fonts can influence the layout calculation
// and cause the subtitles to be misaligned.

// Use this component to only mount components once all fonts are loaded
export const WaitForFonts: React.FC<{
  children: () => React.ReactNode
  fontFamily?: string
}> = ({ children, fontFamily }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false)
  const [handle] = useState(() => delayRender())

  useEffect(() => {
    const delay = delayRender("Waiting for fonts to be loaded")
    const font = top250GoogleFonts.find((availableFont) => {
      if (fontFamily) {
        return availableFont.family === fontFamily
      }
    })
    if (font) {
      font
        .load()
        .then(
          (loaded: {
            loadFont: () => {
              waitUntilDone: () => Promise<undefined>
            }
          }) => {
            const { waitUntilDone } = loaded.loadFont()
            waitUntilDone()
              .then(() => {
                continueRender(handle)
                continueRender(delay)
                setFontsLoaded(true)
              })
              .catch((err: unknown) => {
                cancelRender(err)
              })
          }
        )
        .catch(console.error)
    }
  }, [fontsLoaded, handle, fontFamily])

  if (!fontsLoaded) return null

  return children()
}
