import { ComponentProps } from "react"
import { z } from "zod"

import { cn } from "@/lib/utils"

import { baseTextPropsSchema } from "./validation"

// THOU SHALT KEEP THESE IN SYNC
export const X_PADDING = 8
const x_padding = "px-2"
// THOU SHALT KEEP THESE IN SYNC

export const Text = (
  props: z.infer<typeof baseTextPropsSchema> & ComponentProps<"span">
) => {
  return (
    <span
      {...props}
      style={{ ...props.style }}
      className={cn(
        "w-full whitespace-pre-line py-1",
        x_padding,
        props.className
      )}
    >
      {props.content}
    </span>
  )
}
