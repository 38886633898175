import React, { useMemo } from "react"
import { Audiogram } from "@/features/remotion/components/audiogram"
import { ClippingComp } from "@/features/remotion/components/clipping"
import { DELAY_RENDER_RETRIES } from "@/features/remotion/constants"
import { SubtitlesAtChunk } from "@/features/remotion/subtitles-at-chunk"
import { Text } from "@/features/remotion/text-styles/text"
import { Audio, Img, OffthreadVideo, Series } from "remotion"
import { z } from "zod"

import { getStringifiedShadow } from "@/lib/colors"
import { cn, getElementDomId } from "@/lib/utils"
import { nestedElementSchema } from "@/lib/validations/element"
import { savedInputPropsSchema } from "@/lib/validations/input-props"

import { WaitForFonts } from "../providers/waitForFonts"
import { fitTextElement, fitTextToBox } from "../text-styles/utils"

export const El = ({
  el,
  props,
}: {
  el: z.infer<typeof nestedElementSchema>
  props: z.infer<typeof savedInputPropsSchema>
}) => {
  const content = useMemo(() => {
    switch (el.type) {
      case "audio":
        return (
          <Audio
            pauseWhenBuffering={!props.isBufferingDisabled}
            volume={el.volume}
            src={el.src}
            delayRenderRetries={DELAY_RENDER_RETRIES}
            delayRenderTimeoutInMilliseconds={60 * 1000 * 2}
          />
        )
      case "image":
        return (
          <Img
            src={el.src}
            className="size-full overflow-hidden"
            style={{
              objectFit: el.style.objectFit,
              borderRadius: el.style.borderRadius,
            }}
          />
        )
      case "text":
        return (
          <WaitForFonts
            fontFamily={el.style.fontFamily}
            // eslint-disable-next-line react/no-children-prop
            children={() => (
              <Text
                content={el.text}
                className="overflow-visible"
                style={{
                  fontSize: el.enableFitText
                    ? fitTextToBox(el)
                    : el.style.fontSize,
                  fontFamily: el.style.fontFamily,
                  backgroundColor: el.style.backgroundColor,
                  color: el.style.color,
                  textAlign: el.style.textAlign,
                  fontStyle: el.style.fontStyle,
                  fontWeight: el.style.fontWeight,
                  lineHeight: `${el.style.lineHeightPercent}%`,
                  textTransform: el.style.casing,
                  letterSpacing: el.style.letterSpacing,
                }}
              />
            )}
          />
        )
      case "video":
        return (
          <OffthreadVideo
            src={el.src}
            className="size-full overflow-hidden"
            muted={el.isMuted}
            pauseWhenBuffering={!props.isBufferingDisabled}
            delayRenderTimeoutInMilliseconds={3 * 60 * 1000}
            delayRenderRetries={DELAY_RENDER_RETRIES}
            style={{
              objectFit: el.style.objectFit,
              borderRadius: el.style.borderRadius,
            }}
          />
        )
      case "frame":
        return (
          <div
            className="size-full"
            style={{
              borderRadius: el.style.borderRadius,
              ...(el.style.background
                ? { background: el.style.background }
                : {
                    backgroundColor: el.style.backgroundColor,
                  }),

              ...(el.style.backgroundBlur
                ? {
                    backdropFilter: `blur(${el.style.backgroundBlur}px)`,
                  }
                : {}),

              filter: `${
                "shadow" in el.style && el.style.shadow
                  ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
                  : ""
              } ${
                el.style.layerBlur ? `blur(${el.style.layerBlur}px)` : ""
              }`.trim(),
            }}
            // eslint-disable-next-line react/no-children-prop
            children={el.children?.map((child) => (
              <El key={child.id} el={child} props={props} />
            ))}
          />
        )
      case "rectangle":
        return (
          <div
            className="size-full"
            style={{
              borderRadius: el.style.borderRadius,
              ...(el.style.background
                ? { background: el.style.background }
                : {
                    backgroundColor: el.style.backgroundColor,
                  }),

              ...(el.style.backgroundBlur
                ? {
                    backdropFilter: `blur(${el.style.backgroundBlur}px)`,
                  }
                : {}),

              filter: `${
                "shadow" in el.style && el.style.shadow
                  ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
                  : ""
              } ${
                el.style.layerBlur ? `blur(${el.style.layerBlur}px)` : ""
              }`.trim(),
            }}
          />
        )
      case "waveform":
        return (
          <ClippingComp {...props} disablePremounting sequenceProps={{}}>
            {({ startFromFrame, endFrame }) => {
              // We create sequence of the audio segments that overlap the clip
              const audioSegments = (
                props.commonProperties?.audioSegments ?? []
              ).filter(
                // keep all audio segments that overlap
                ({ endFrame: end, startFromFrame: start }) =>
                  start < endFrame && end > startFromFrame
              )
              // legacy path
              if (!audioSegments?.length)
                return (
                  <Audiogram
                    startFrom={startFromFrame}
                    audioSrc={props.commonProperties.sermonAudioUrl}
                    numberOfSamples={256}
                    el={el}
                    mirroring={el.mirroringEnabled ?? false}
                  />
                )
              return (
                <Series>
                  {audioSegments.map((audioSegment) => {
                    return (
                      <Series.Sequence
                        durationInFrames={
                          audioSegment.endFrame - startFromFrame
                        }
                        key={audioSegment.startFromFrame}
                      >
                        {!!audioSegment && (
                          <Audiogram
                            startFrom={Math.max(
                              startFromFrame - audioSegment.startFromFrame,
                              0
                            )}
                            audioSrc={audioSegment.url}
                            numberOfSamples={256}
                            el={el}
                            mirroring={el.mirroringEnabled ?? false}
                          />
                        )}
                      </Series.Sequence>
                    )
                  })}
                </Series>
              )
            }}
          </ClippingComp>
        )
      case "clipping":
        return (
          <ClippingComp
            {...props}
            disablePremounting={false}
            sequenceProps={{
              className: "relative size-full overflow-hidden bg-black",
              // @ts-expect-error style
              style: {
                ...el.style,
              },
            }}
          />
        )
      case "subtitles":
        return (
          <WaitForFonts
            fontFamily={el.style.fontFamily}
            // eslint-disable-next-line react/no-children-prop
            children={() => (
              <ClippingComp {...props} disablePremounting sequenceProps={{}}>
                {({ startFromFrame, endFrame, key }) => (
                  <p
                    className="size-full"
                    style={{
                      textAlign: el.style.textAlign,
                      fontFamily: el.style.fontFamily,
                      color: el.style.color,
                      textTransform: el.style.casing,
                      backgroundColor: el.style.backgroundColor,
                      paddingTop: el.style.paddingVertical,
                      paddingBottom: el.style.paddingVertical,
                      paddingLeft: el.style.paddingHorizontal,
                      paddingRight: el.style.paddingHorizontal,
                      borderRadius: el.style.borderRadius,
                      lineHeight: `${el.style.lineHeightPercent}%`,
                    }}
                    key={key}
                  >
                    <SubtitlesAtChunk
                      clippingBasedProperties={props.clippingBasedProperties}
                      wordsAtATime={el.wordsAtATime}
                      animationStyle={el.animationStyle}
                      startFromFrame={startFromFrame}
                      endFrame={endFrame}
                      highlightBackgroundColor={
                        el.style.disableHighlight
                          ? "transparent"
                          : el.style.highlightBackgroundColor
                      }
                      highlightTextColor={
                        (el.style.disableHighlight
                          ? el.style.color
                          : el.style.highlightTextColor) ?? "#ffffff"
                      }
                      highlightFontStrokeWidth={
                        el.style.highlightFontStrokeWidth
                      }
                      style={{
                        color: el.style.color,
                      }}
                      inactiveColor={el.inactiveColor}
                    >
                      {(word, allWordsInCurrentChunk) => {
                        const {
                          color: _1,
                          fontSize,
                          paddingHorizontal: _2,
                          paddingVertical: _3,
                          borderRadius: _4,
                          backgroundColor: _5,
                          fontWeight: inactiveFontWeight,
                          ...restStyles
                        } = el.style
                        return (
                          <Text
                            content={word}
                            style={{
                              fontSize: !el?.disableFitText
                                ? fitTextElement(allWordsInCurrentChunk, el)
                                : fontSize,
                              fontWeight: el.style.highlightFontStrokeWidth
                                ? undefined
                                : inactiveFontWeight,
                              ...restStyles,
                            }}
                          />
                        )
                      }}
                    </SubtitlesAtChunk>
                  </p>
                )}
              </ClippingComp>
            )}
          />
        )
      // document
      default:
        return null
    }
  }, [el, props])

  return (
    <div
      key={el.id}
      className={cn(
        "absolute z-[100]",
        el.hidden ? "hidden" : "flex",
        el.type !== "subtitles" && el.type !== "rectangle" && "overflow-hidden",
        el.type
        // el.type === "subtitles" && "flex items-center justify-center"
      )}
      id={getElementDomId(el.id)}
      style={{
        ...("transform" in el
          ? {
              left: el.transform.x,
              top: el.transform.y,
              width: el.transform.width,
              height:
                el.type === "text" || el.type === "subtitles"
                  ? "fit-content"
                  : el.transform.height,
              opacity: el.style.opacity,
              ...(el.style.layerBlur
                ? {
                    filter: `blur(${el.style.layerBlur}px)`,
                  }
                : {}),

              filter: `${
                "shadow" in el.style &&
                el.style.shadow &&
                el.type !== "rectangle"
                  ? `drop-shadow(${getStringifiedShadow(el.style.shadow)}) `
                  : ""
              }`.trim(),

              backdropFilter: `${
                "backgroundBlur" in el.style &&
                el.style.backgroundBlur &&
                el.type !== "rectangle"
                  ? `blur(${el.style.backgroundBlur}px)`
                  : ""
              }`.trim(),
            }
          : {}),
      }}
    >
      {content}
    </div>
  )
}
