import { visualizeAudio } from "@remotion/media-utils"
import { Series, useCurrentFrame, useVideoConfig } from "remotion"
import { z } from "zod"

import { BarsVisualization } from "@postsunday/remotion-shared/audiogram-styles/bars"
import { HillsVisualization } from "@postsunday/remotion-shared/audiogram-styles/hills-visualization"
import { RadialBarsVisualization } from "@postsunday/remotion-shared/audiogram-styles/radial-bars"
import { WaveVisualization } from "@postsunday/remotion-shared/audiogram-styles/wave"
import { useAudioData } from "@postsunday/remotion-shared/hooks/useAudioData"
import { audiogramElementSchema } from "@postsunday/remotion-shared/lib/validations/element"
import { cn } from "@postsunday/shared/lib/utils"

export const Audiogram = ({
  numberOfSamples,
  el,
  mirroring,
  audioSegments,
  startFromFrame,
  endFrame,
  ...props
}: {
  numberOfSamples: number
  el: z.infer<typeof audiogramElementSchema>
  mirroring: boolean
  audioSegments: { url: string; startFromFrame: number; endFrame: number }[]
  startFromFrame: number
  endFrame: number
} & React.HTMLAttributes<HTMLDivElement>) => {
  const frame = useCurrentFrame()
  const { fps } = useVideoConfig()

  // Handle segmented audio if provided
  if (
    !audioSegments.length ||
    startFromFrame === undefined ||
    endFrame === undefined
  )
    throw new Error(
      "Audio segments must be provided with startFromFrame and endFrame" +
        `${audioSegments.length} ${startFromFrame} ${endFrame}`
    )

  const relevantSegments = audioSegments.filter(
    ({ endFrame: end, startFromFrame: start }) =>
      start < endFrame && end > startFromFrame
  )

  if (relevantSegments.length) {
    return (
      <Series>
        {relevantSegments.map((segment) => {
          const segmentStart = Math.max(
            startFromFrame - segment.startFromFrame,
            0
          )
          return (
            <Series.Sequence
              durationInFrames={segment.endFrame - startFromFrame}
              key={segment.startFromFrame}
            >
              <AudiogramSegment
                key={segment.startFromFrame}
                segment={segment}
                segmentStart={segmentStart}
                frame={frame}
                fps={fps}
                numberOfSamples={numberOfSamples}
                el={el}
                mirroring={mirroring}
                {...props}
              />
            </Series.Sequence>
          )
        })}
      </Series>
    )
  }
  return null
}

const AudiogramSegment = ({
  segment,
  segmentStart,
  frame,
  fps,
  numberOfSamples,
  el,
  mirroring,
  ...props
}: {
  segment: { url: string; startFromFrame: number; endFrame: number }
  segmentStart: number
  frame: number
  fps: number
  numberOfSamples: number
  el: z.infer<typeof audiogramElementSchema>
  mirroring: boolean
} & React.HTMLAttributes<HTMLDivElement>) => {
  const audioData = useAudioData(segment.url, {
    retries: 3,
    timeoutInMilliseconds: 90 * 1000,
  })

  if (!audioData) return null

  return (
    <AudiogramVisualization
      frequencyData={visualizeAudio({
        optimizeFor: "speed",
        fps,
        frame: frame + segmentStart,
        audioData,
        numberOfSamples,
      })}
      el={el}
      mirroring={mirroring}
      {...props}
    />
  )
}

const AudiogramVisualization = ({
  frequencyData,
  el,
  mirroring,
  ...props
}: {
  frequencyData: number[]
  el: z.infer<typeof audiogramElementSchema>
  mirroring: boolean
} & React.HTMLAttributes<HTMLDivElement>) => {
  const nonAutoWidth = el.transform.width !== "auto" ? el.transform.width : 256
  const nonAutoHeight =
    el.transform.height !== "auto" ? el.transform.height : 256

  const audiogramComponent = () => {
    // case missing: undefined
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (el.audiogramStyleId) {
      case "bars":
        return (
          <BarsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            lineThickness={5}
            gapSize={7}
            roundness={2}
            mirroring={mirroring}
            color={el.style.color ?? "#F3B3DC"}
          />
        )
      case "radial-bars":
        return (
          <RadialBarsVisualization
            frequencyData={frequencyData}
            diameter={nonAutoWidth}
            innerRadius={nonAutoWidth / 4}
            color={el.style.color ?? "#DCBC8A"}
          />
        )
      case "hills":
        return (
          <HillsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            fillColor={["#559B59", "#466CF6", "#E54B41"]}
            copies={3}
            blendMode="screen"
          />
        )
      case "wave":
        return (
          <WaveVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            offsetPixelSpeed={200}
            lineColor={[el.style.color ?? "#EE8482", "teal"]}
            lineGap={(2 * 280) / 8}
            topRoundness={0.2}
            bottomRoundness={0.4}
            sections={Math.max(4, Math.floor(nonAutoWidth / 100))}
          />
        )
      case "mono-hill":
        return (
          <HillsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            fillColor={[el.style.color ?? "#559B59"]}
          />
        )
      case "replicated-waves":
        return (
          <WaveVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            offsetPixelSpeed={-100}
            lineColor={el.style.color ?? "#EE8482"}
            lineGap={6}
            topRoundness={0.2}
            bottomRoundness={0.4}
            lines={6}
            sections={Math.max(4, Math.floor(nonAutoWidth / 100))}
          />
        )
    }
  }

  return (
    <div
      {...props}
      className={cn(
        "flex items-center justify-center gap-x-2",
        props.className
      )}
    >
      {audiogramComponent()}
    </div>
  )
}
