import { Shadow } from "@postsunday/shared/lib/validations/styles"

const toPx = (n: number | string): string | number =>
  typeof n === "number" && n !== 0 ? `${n}px` : n

export function getStringifiedShadow(shadow: Shadow): string {
  const { x = 0, y = 0, blur = 0, color } = shadow ?? {}

  return [
    x,
    y,
    blur,
    // spread,
    color,
  ]
    .filter(<T>(v: T): v is NonNullable<T> => v !== null && v !== undefined)
    .map(toPx)
    .join(" ")
}
